<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>List Stock Opname</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-button variant="primary" v-b-modal.modal-input-stockopname
                    ><CIcon name="cil-plus" /> Tambah Data</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" md="12" lg="12">
                  <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive
                    show-empty
                    small
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                    <template #cell(actions)="item">
                      <b-button
                        variant="secondary"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Detail'"
                        @click="goDetail('/gudang/stock_opname/detail_stock_opname', { item } , $event)"
                        ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        v-if="item.item.submitted == 1"
                        variant="warning"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Add Detail'"
                        @click="goAddDetail('/gudang/stock_opname/addDetail_stock_opname', { item } , $event)"
                        ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        v-if="item.item.submitted == 1"
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Delete'"
                        v-b-modal.modal-delete-stockopname
                        @click="is_data = item.item"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                    </template>
                  </b-table>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Per Halaman"
                    label-for="per-page-select"
                    label-cols-md="6"
                    label-align-md="left"
                    label-size="md"
                    style="background-color: "
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="md"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3" offset-md="6">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <modal-input 
        @alertFromChild="triggerAlert($event), getDatas()"
        :list_gudang="list_gudang"
      />
      <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  import ModalInput from "./modalInput.vue";
  import ModalDelete from "./modalDelete.vue";
  // import { useGetAxios } from "@/composables/useAxios.js";
  
  export default {
    name: "ListStockOpname",
    components: {
        ModalInput,
        ModalDelete
    },
    data() {
      return {
        showing: false,
        variant: "success",
        list_gudang: [],
        msg: "",
        is_data: "",
        fields: [
          {
            key: "no",
            label: "No",
            sortDirection: "desc",
            sortable: true,
            class: "table-number text-center col-1",
          },

          {
            key: "no_stock_opname",
            label: "No. Stock Opname",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
  
          {
            key: "nama_gudang",
            label: "Nama Gudang",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          
          {
            key: "tanggal",
            label: "Tanggal",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "created_by",
            label: "Created By",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
  
          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        tableBusy: false,
  
        data_res: null,
        data_err: null,
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
    },
    async mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.getDatas();
  
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getDatas() {
        try {
          this.tableBusy = true;
          let gudang = await this.$axios.post("/ms_gudang/list");
            console.log(gudang);
            this.list_gudang = gudang.data.data

          let list_opname = await this.$axios.post("/stock_opname/list");
          let x = list_opname.data;
          console.log(list_opname, 'ini ress')
          if (x.status === 200 && x.message === "sukses") {
            this.items = list_opname.data.data;
            for (let i = 0; i < this.items.length; i++) {
              let x = this.items[i];
              x.no = i + 1;
              x.tanggal = this.$moment(x.tanggal_stock_opname).format("DD-MM-YYYY HH:mm")
            }
            this.totalRows = this.items.length;
          } else {
            throw x;
          }
        } catch (err) {
          if (err.message) {
            this.$store.commit("set_alert", {variant: "danger", msg: _.toUpper(err.message), showing: true});
          } else {
            this.$store.commit("set_alert", {variant: "danger", msg: _.toUpper(err), showing: true});
          }
        } finally {
          this.tableBusy = false;
        }
      },
      goDetail(action, data) {
        // console.log(data, 'ini datanyaaaa');
        this.$router.push({
          path: action,
          query: { id_opname : data.item.item.stock_opname_id, id_gudang:data.item.item.ms_gudang_id, nama_gudang:data.item.item.nama_gudang },
        });
      },
      goAddDetail(action, data) {
        console.log(data, 'ini datanyaaaa');
        this.$router.push({
          path: action,
          query: { id_opname : data.item.item.stock_opname_id, id_gudang:data.item.item.ms_gudang_id, nama_gudang:data.item.item.nama_gudang },
        });
      },
      goList(action, data) {
        // console.log(data, 'ini datanyaaaa');
        this.$router.push({
          path: action,
          query: { id : data.item.item.id },
        });
      },
      triggerAlert(event) {
        let vm = this;
        // console.log('ihiyyyyyyyyyyyy');
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>