<template>
    <div>
      <b-modal
        id="modal-input-stockopname"
        size="lg"
        centered
        title="Tambah Data Stock Opname"
        header-bg-variant="primary"
        header-text-variant="light"
        @hidden="resetModal"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Gudang <span class="text-danger">*</span>
            </template>
            <Multiselect
                :options="list_gudang"
                :state="checkIfValid('gudangID')"
                v-model="$v.is_data.gudangID.$model"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_gudang"
                track-by="id"
                placeholder="--Pilih Gudang--"
            ></Multiselect>
          </b-form-group>
        </b-form>
        <!-- <img :src="src1" /> -->
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-stockopname')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import Multiselect from "vue-multiselect";
  import moment from "moment"
  moment.locale("ID")
  export default {
    name: "modalInput",
    props: ['list_gudang'],
    components: {
        Multiselect,
      },
    data() {
      return {
        is_data: {
          tanggal_stock_opname: "",
          gudangID: "",
          ms_gudang_id: "",
          created_by: "",
        },
        src1: "",
        selected: null,
        busy: false,
        button: "Simpan",
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      is_data: {
        gudangID: {
          required,
        }
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        vm.is_data.created_by = vm.$store.state.nama_user
        vm.is_data.ms_gudang_id = vm.is_data.gudangID.ms_gudang_id
        vm.is_data.tanggal_stock_opname = vm.$moment(new Date).format("YYYY-MM-DD HH:mm")
        console.log(vm.is_data, 'ini isdata');
        vm.$axios
          .post("/stock_opname/register", vm.is_data)
          .then((res) => {
            console.log(res, 'ini respon cuii');
            if (res.data.message == "sukses") {
                vm.$bvModal.hide('modal-input-stockopname')
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN STOCK OPNAME",
                showing: true,
              });
              this.$router.push({
                path: '/gudang/stock_opname/addDetail_stock_opname',
                query: { id_opname : res.data.id, id_gudang: vm.is_data.ms_gudang_id, nama_gudang: vm.is_data.gudangID.nama_gudang },
              });
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      resetModal() {
        this.$v.$reset();
        this.is_data = {
          gudangID: "",
        };
      },
    },
  };
  </script>
  